export class FetchError extends Error {
  status: number
  data?: unknown

  constructor(status: number, data?: unknown) {
    super(
      typeof data === 'string'
        ? data
        : 'An error occurred while fetching the data.'
    )
    this.status = status
    this.data = data
  }
}

export function isFetchError(error: unknown): error is FetchError {
  return (
    typeof error === 'object' &&
    !!error &&
    'message' in error &&
    'status' in error &&
    typeof (error as FetchError).status === 'number' &&
    typeof (error as FetchError).message === 'string'
  )
}
